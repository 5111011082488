import { useTable } from "react-table";
import { ReactComponent as SpinnerIcon } from "../../../assets/img/icons/spinner.svg";
import ReactTooltip from 'react-tooltip';
import { useEffect } from "react";


export function Table({ className, columns, data, loadingContent }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map(column => {
            if (column.show === false) return column.accessor || column.id;
        }),}
    });

    useEffect(() => {
      ReactTooltip.rebuild();
    }
    , [data]);

  // Render the UI for your table
  return (
    <div
      className={`bg-playmove-lightblue rounded-md ${className} px-6 pt-3 pb-7 overflow-x-auto`}
    >
      <ReactTooltip className="!rounded-xl"/>  
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="text-left text-xl font-dosisbold py-3 px-3 border-b-2 border-gray-300 text-playmove-darkgray"
                    style={{width: column.width}}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {loadingContent ? (
              <tr>
                <td colSpan={columns.length}>
                  <SpinnerIcon className="m-auto w-32" />
                </td>
              </tr>
            ) : 
            rows.length > 0 ?   
            (
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="py-5 text-xl px-3 border-b border-gray-300 font-bold text-playmove-gray"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })) :
            (
              <tr>
                <td colSpan={columns.length}>
                  <p className="text-center text-xl py-3">Nenhum registro encontrado</p>
                </td>
              </tr>
            )}          
          </tbody>

        </table>
    </div>
  );
}
