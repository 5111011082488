import {useState, useContext, useEffect} from "react";
import SidebarButton from "./SidebarButton";
import UserInfo from "./UserInfo";
import {ReactComponent as PlaymoveAcademiaIcon} from "../../assets/img/icons/playmove-academia.svg";
import {ReactComponent as SairIcon} from "../../assets/img/icons/sair.svg";
import {ReactComponent as CursosIcon} from "../../assets/img/icons/cursos.svg";
import {ReactComponent as JogosIcon} from "../../assets/img/icons/jogos.svg";
import {ReactComponent as AlunosIcon} from "../../assets/img/icons/alunos.svg";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons'
import {Link, useLocation} from "react-router-dom";
import {UserInfoContext} from "../../Contexts/UserInfoContext";


export default function Sidebar() {
    const [showSidebar, setShowSidebar] = useState(false);
    const sidebarClass = showSidebar ? "" : "-translate-x-[100vw] md:translate-x-0";
    const [userInfo, setUserInfo] = useContext(UserInfoContext);
    const userIsAdmin = userInfo.role === "Admin" || userInfo.role === "PlaymoveAdmin";
    const location = useLocation();
    const isHome = location.pathname === "/home";

    const SidebarButtons = () => {
        return (
            <div className="grid content-center gap-y-4 pb-5 ">
                <SidebarButton onClick={() => setShowSidebar(false)} path="/cursos" aria-label="Acessar Cursos" divClassName="">
                    <CursosIcon className="cursor-pointer stroke-inherit w-10"/>
                    <p className="text-center text-sm text-white ">cursos</p>
                </SidebarButton>
                {/*<SidebarButton onClick={() => setShowSidebar(false)} path="/jogos" fill aria-label="Acessar Jogos" divClassName="">
                    <JogosIcon className="cursor-pointer fill-inherit w-10"/>
                    <p className="text-center text-sm text-white">jogos</p>
                </SidebarButton> */}
                {userIsAdmin && 
                <SidebarButton onClick={() => setShowSidebar(false)} path="/cadastros" fill aria-label="Acessar Cadastros" divClassName="">
                    <AlunosIcon className="cursor-pointer fill-inherit w-10"/>
                    <p className="text-center text-sm text-white">cadastros</p>
                </SidebarButton>}
            </div>
        );
    };

    const FloatingShowSidebarButton = () => {
        return !showSidebar ? (<div
                className={`${isHome && 'animate-[sidebarButtonGrow_4s_ease-in-out]'} w-8 overflow-hidden flex drop-shadow-lg mt-2 ml-2 z-50 pl-[0.55rem] h-8 origin-left cursor-pointer bg-playmove-blue rounded-full top-0 absolute  md:hidden`}
                onClick={() => setShowSidebar(!showSidebar)}>
                <FontAwesomeIcon className="my-auto" color="white" icon={faBars}/>
                <span className="self-center text-white font-dosisbold pl-3">menu</span>
            </div>
        ) : null;
    };

    const FixedShowSidebarButton = () => {
        return showSidebar ? (
            <div className="flex justify-center drop-shadow-lg z-50 cursor-pointer top-0 left-0 absolute md:hidden"
                 onClick={() => setShowSidebar(!showSidebar)}>
                <FontAwesomeIcon className="h-5 w-5 p-2" color="white" icon={faBars}/>
            </div>
        ) : null;
    };

    return (
        <>
            {FloatingShowSidebarButton()}
            <div
                className={`${sidebarClass} fixed z-40 transition ease-in-out top-0 flex flex-col h-full md:sticky md:w-36 bg-playmove-blue py-5 px-5 justify-items-center`}>
                {FixedShowSidebarButton()}
                <div className="grid pt-5 justify-items-center self-start gap-y-8">
                    <Link to="/home" aria-label="Voltar ao Inicio" onClick={() => setShowSidebar(false)}>
                        <PlaymoveAcademiaIcon
                            className="h-15 cursor-pointer  fill-white transition duration-200 ease-out hover:ease-in  hover:fill-playmove-yellow"/>
                    </Link>
                    <UserInfo />
                </div>
                {SidebarButtons()}
                <SidebarButton path="/logout" stroke aria-label="Sair" divClassName="mt-auto">
                    <SairIcon className="cursor-pointer stroke-inherit w-8"/>
                    <p className="text-center text-base text-white">sair</p>
                </SidebarButton>
            </div>
        </>
    );
}
