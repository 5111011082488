import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import Home from "./Components/Home";
import Cursos from "./Components/Cursos";
import Trilha from "./Components/Trilha";
import Curso from "./Components/Curso";
import Jogos from "./Components/Jogos";
import Cadastros from "./Components/Cadastros";
import Error from "./Components/Error";
import Login from "./Components/Login";
import NovaSenha from "./Components/NovaSenha";
import Logout from "./Components/Logout";
import {UserInfoContextProvider} from "./Contexts/UserInfoContext";
import EditarCadastro from "./Components/EditarCadastro"; 
import ClassForm from './Components/Cadastros/Turmas/ClassForm';
import CursoConcluido from "./Components/CursoConcluido";
import TrilhaConcluida from "./Components/TrilhaConcluida";


export default function App() {
    return (
        <BrowserRouter>
                <UserInfoContextProvider>
                    <Routes>
                        <Route path="/" element={<Login/>}/>
                        <Route path="/login" element={<Login/>}/>                        
                        <Route path="/logout" element={<Logout/>}/>
                        <Route path="/novasenha/:forgotPasswordToken" element={<NovaSenha/>}/>
                        <Route path="/home" element={<Home/>}/>
                        <Route path="/cursos" element={<Cursos/>}/>
                        <Route path="/editar-cadastro" element={<EditarCadastro/>}/>
                        <Route path="/cursos/:id" element={<Curso/>}/>
                        <Route path="/cursos/:id/concluido" element={<CursoConcluido/>}/>
                        <Route path="/trilhas/:id" element={<Trilha/>}/>
                        <Route path="/trilhas/:id/concluido" element={<TrilhaConcluida/>}/>
                        <Route path="/jogos" element={<Jogos/>}/>
                        <Route path="/cadastros" element={<Cadastros section="Escolas"/>}/>                       
                        <Route path="/cadastros/escolas" element={<Cadastros section="Escolas"/>}/>                        
                        <Route path="/cadastros/turmas" element={<Cadastros section="Turmas"/>}/>  
                        <Route path="/cadastros/turmas/:id" element={<ClassForm edit/>}/>    
                        <Route path="/cadastros/turmas/nova" element={<ClassForm/>}/>                         
                        <Route path="/cadastros/alunos" element={<Cadastros section="Alunos"/>}/>
                        <Route path="/error404" element={<Error type="404"/>}/>
                        <Route path="/error500" element={<Error type="500"/>}/>
                        <Route path="*" element={<Error type="404"/>}/>
                    </Routes>
                </UserInfoContextProvider>
        </BrowserRouter>
    );
}
